import {
    DocumentsWidget,
    FavoriteEserviceWidget, FavoriteWindowWidget,
    MyEparapheurWidget,
    ProceduresWidget,
    TrainingProgramWidget
} from "../widgets";


export const AccueilPage = () => {

  return (
      <div className="flex flex-col gap-3">
          <div className="grid grid-cols-2 gap-2">
             <ProceduresWidget/>
             <MyEparapheurWidget/>
          </div>
           <DocumentsWidget/>
          <div className="grid grid-cols-2 gap-2">
            <FavoriteWindowWidget/>
            <FavoriteEserviceWidget/>
          </div>
          <TrainingProgramWidget/>
      </div>
  )
}
