import {useLocation, useParams} from "react-router-dom";
import {USER_ROUTE_LOCATION_PATHNAME, WINDOW_ROUTE_LOCATION_PATHNAME} from "../utils/constants";

interface RouteParams extends Record<string, string | undefined> {
    guichetId?: string;
}
export const useHandleDomLocation=()=>{
    const location=useLocation();
    const {guichetId}=useParams<RouteParams>()
    const profilePathName=location.pathname.split("/")[1];
    const isWindowProfile=profilePathName===WINDOW_ROUTE_LOCATION_PATHNAME;
    const isUserProfile=profilePathName===USER_ROUTE_LOCATION_PATHNAME;
    return {isUserProfile,profilePathName,isWindowProfile,windowId:guichetId};
}