import { ChangeEvent, useEffect, useState } from "react";
import FilterSvg from "../../../../../shared/components/icons/FilterSvg";
import PeopleSvg from "../../../../../shared/components/icons/PeopleSvg";
import StarSvg from "../../../../../shared/components/icons/StarSvg";
import { FilterBar } from "../../../components/ui/filter-bar/FilterBar"
import { FilterBarContentProps } from "../../../components/ui/filter-bar/FilterBarContent";
import { FilterBarInputProps } from "../../../components/ui/filter-bar/FilterBarInput";
import ListSvg from "../../../../../shared/components/icons/ListSvg";
import GridSvg from "../../../../../shared/components/icons/GridSvg";
import { useCreateEservice, useFavoriteEservice, useGetCategoriesList, useGetEserviceList, useRequestJoinEservice } from "../../../api";
import { Input, Modal, MultipleAdvancedDropDownList, TextArea } from "kls-ui";
import { MAX_CARACTER_NAME } from "../../../utils/constants/helpers.constants";
import { cleanAndFormatNameResource } from "../../../utils/helpers/validators.helper";
import { CardGrid } from "../../../components/ui/card-grid/CardGrid";
import { PageContentContainer } from "../../../layouts";
import { ROLE_LIST_ESERVICE } from "../../../utils/constants/components.constants";
import { convertToEserviceCard } from "../../../api/eservice/formatteur";
import { Table } from "../../../components/ui/table/Table";
import { useNavigate, useParams } from "react-router-dom";
import { FavoriteEserviceRequest, ListEserviceRequest } from "../../../types";
import { message } from "antd";
import { BreadCrumb, EmptyData, InputText } from "../../../../../shared/components";
import styles from './eservices-pages.module.css'
import { MaterialIcon } from "../../../../../shared/components/icons";
import { FilterBarSortByProps } from "../../../components/ui/filter-bar/FilterBarSortBy";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedEservice } from "../../../features/eservices/eservicesSlice";
import { useHandleDomLocation } from "../../../hooks";
import { Eservice } from "../../../components/ui/cards/base-cards/CardEservice";
import { RootState } from "../../../../../shared/stores/store";

export const EserviceListPage = () => {
  const dispatch = useDispatch();
  const selectedGuichet = useSelector((state: RootState) => state.guichets.selectedGuichet);
  const { profilePathName } = useHandleDomLocation()
  const [searchValue, setSearchValue] = useState<string>('');
  const [favorite, setFavorite] = useState<boolean>(false);
  const [contribute, setContribute] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<{
    [key: string]: string | undefined;
  }>({ value: 'recent', label: 'Récent' });
  const [showGrid, setShowGrid] = useState<boolean>(true);
  const [showGridEshop, setShowGridEshop] = useState<boolean>(true);

  const [showTable, setShowTable] = useState<boolean>(false);
  const [showTableEshop, setShowTableEshop] = useState<boolean>(false);

  const [selectedElemnt, setSelectedElement] = useState("");
  const [selectedCardOption, setSelectedCardOption] = useState<{ id: string; selectedValue: string } | null>(null);

  const [errorMessageCrea, setErrorMessageCrea] = useState('');
  const [errorMessageJoin, setErrorMessageJoin] = useState('');

  const [nomService, setNomService] = useState('');
  const [note, setNote] = useState('');

  const [totalNumber, setTotalNumber] = useState(0);
  const [reset, setReset] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const emptyIcon = <i className="material-flex-icon text-blue-500 text-3xl">search_off</i>
  const { guichetId } = useParams<{ guichetId: string }>();
  const [idGuichet,setIdGuichet]=useState<string>(guichetId || '');
  const navigate = useNavigate();
  const [showEshop,SetShowEshop]=useState(false);
  
  const [allEservicesParams, setAllEservicesParams] =
    useState<ListEserviceRequest>({
      numPage: 1,
      pageSize: 10,
      contributeur: false,
      status: '',
      reference: '',
      favorite: false,
      industry: '',
      entity: '',
      guichetName: '',
      idGuichet: idGuichet,
      typePublication: '',
      role: '',
      Fields: 'ALL'
    });
    const [selectedItems, setSelectedItems] = useState<Array<Eservice>>([]);

  const [favoriteRequest, setFavoriteRequest] = useState<FavoriteEserviceRequest>({
    idEservice: '',
    favorite: false
  });
  const getEserviceList = useGetEserviceList(allEservicesParams);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getCategoriesList = useGetCategoriesList();

  const [cardPropsData, setCardPropsData] = useState<Eservice[]>([]);
  const [values, setValues] = useState<string[]>([]);
  const filteredRoleList = ROLE_LIST_ESERVICE.filter(role => {
    if (values.includes('administrateur') && role.value === 'citizen developer') {
      return false;
    }
    if (values.includes('citizen developer') && role.value === 'administrateur') {
      return false;
    }
    return true;
  });

  const postCreateEservice = useCreateEservice();
  useFavoriteEservice(favoriteRequest);

  const postRequestJoinEservice = useRequestJoinEservice();
  const [messageApi, contextHolder] = message.useMessage();
  const [guichetName, setGuichetName] = useState<string>('');
  const [industry, setIndustry] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [visibility, setVisibility] = useState<string>('');
  const [roleFiltre, setRoleFiltre] = useState<string>('');

  const handleNavigaeDropDownListButton = (selectedElements: string[]) => {
    switch (selectedElements[0]) {
      case "scratch": setSelectedElement('scratch')
    }
  }

  const handleCloseEshop = ()=>{
    setCardPropsData([]);
    if (page > 1) {
      setPage(1);
    }
    setVisibility('');
    setStatus('');
    SetShowEshop(!showEshop);
    setIdGuichet(guichetId || '');
  }

  const handlOpenEshop = ()=>{
    setIdGuichet('');
    setVisibility('');
    // setStatus('Public');
    SetShowEshop(!showEshop);
    
  }

  useEffect(() => {
    const storedName = localStorage.getItem('guichetName') || '';
    setGuichetName(storedName);
  }, []);
  useEffect(() => {
    if (postRequestJoinEservice.isPending) {
      messageApi.loading({
        content: 'Loading...',
        duration: 0
      });

    }
    if (postRequestJoinEservice.customHandler.isSuccess) {
      messageApi.destroy();
      messageApi.success({
        content: 'Votre demande a été soumise avec succès',
        duration: 2.5
      });
    }
    if (postRequestJoinEservice.customHandler.isError) {
      messageApi.destroy();

      messageApi.error({
        content: postRequestJoinEservice.customHandler.errorMessage,
        duration: 2.5
      });
    }


  }, [postRequestJoinEservice.status]);

  const fBsEserv: FilterBarSortByProps = {
    items: [
      {
        data: getCategoriesList.customHandler.data,
        label: 'Catégorie(s)',
        placeHolder: 'Catégorie(s)',
        selectedValue: industry,
        onChange: (e: string) => {
          {
            if (page > 1) {
              setPage(1);
            }
            if (e == industry) return;
            setCardPropsData([]);
            setIndustry(e);
          }
        }

      }, {
        data: [
          {
            label: 'Brouillon',
            value: 'Brouillon'
          },
          {
            label: 'Publiée',
            value: ''
          },
          {
            label: 'Non Publiée',
            value: ''
          },


        ],
        label: 'Statut',
        placeHolder: 'Statut',
        selectedValue: status,
        onChange: (e: string) => {
          {
            if (page > 1) {
              setPage(1);
            }
            if (e == status) return;
            setCardPropsData([]);
            setStatus(e);
          }
        }

      },
      {
        data: [
          { label: 'Publique', value: '' },
          { label: 'Privé', value: '' },

        ],
        label: 'Visibilité',
        placeHolder: 'Visibilité',
        selectedValue: visibility,
        onChange: (e: string) => {
          {
            if (page > 1) {
              setPage(1);
            }
            if (e == visibility) return;
            setCardPropsData([]);
            setVisibility(e);
          }
        }
      }
      , {
        data: [
          { label: 'Propriétaire', value: 'Proprietaire' },
          { label: 'Administrateur', value: 'Administrateur' },
          { label: 'Développeur citoyen', value: 'Citizen Developer' },
          { label: 'Contibuteur', value: 'Contibuteur' }

        ],
        label: 'Role',
        placeHolder: 'Role',
        selectedValue: roleFiltre,
        onChange: (e: string) => {
          {
            if (page > 1) {
              setPage(1);
            }
            if (e == roleFiltre) return;
            setCardPropsData([]);
            setRoleFiltre(e);
          }
        }
      }


    ]
  };

  const fBc: FilterBarContentProps = {
    label: 'e-Services',
    searchLength: totalNumber,
    buttons: [
      {
        icon: StarSvg,
        label: 'Mes favorites',
        isSelected: favorite,
        handleClick: (favorite) => {
          if (page > 1) {
            setPage(1);
          }
          setCardPropsData([]);
          setFavorite(!favorite);
        }
      },
      {
        icon: PeopleSvg,
        label: 'Où je contribue',
        isSelected: contribute,
        handleClick: (contribute) => {
          if (page > 1) {
            setPage(1);
          }
          setCardPropsData([]);
          setContribute(!contribute);
        }
      }
    ],
    ...(selectedGuichet.typeGuichet == 'e-Shop' ? {
    buttonAddArgs: {
      label: 'Ajouter e-service',
      args: { onClick: handlOpenEshop },
      color: 'primary',
      size: 'sm',
      styling: 'solid',
      leftIcon:<MaterialIcon label="add"/>
    }, 
  }
    :
    {dropDownListButton: {
      buttonAttribute: {
        label: 'Nouveau e-service',
        args: {},
        color: "primary",
        styling: "solid",
        size: "sm",
        rightIcon: <MaterialIcon label="expand_more" />
      },
      menuAttributes: {
        onChange: handleNavigaeDropDownListButton,
        list: [
          {
            label: 'À partir de zéro',
            value: 'scratch',
            icon: <MaterialIcon label="web_asset" className="text-base text-blue-600" />,
          },
          {
            label: 'À partir du catalogue',
            value: 'catalogue',
            icon: <MaterialIcon label="grid_view" className="text-base text-pink-600" />,
          },
          {
            label: `À partir d'excel`,
            value: 'excel',
            icon: <MaterialIcon label="table_chart" className="text-base text-pink-600" />,
          },
          {
            label: 'Via l’assistant',
            value: 'assistant',
            icon: <MaterialIcon label="corporate_fare" className="text-base text-pink-600" />,
          },

        ]
      }
    }
    }),
  };
  const fBi: FilterBarInputProps = {
    filterBarInputId: 'search',
    filterBarInputPlaceHolder: 'Rechercher',
    filterBarInputType: 'text',
    filterBarInputValue: searchValue,
    filterButton: {
      handleClick: (showFilters) => {
        setShowFilters(!showFilters);
      },
      icon: FilterSvg,
      label: 'Filtres',
      selected: showFilters,
      reset: reset,
      resetClick: () => {
        setRoleFiltre('');
        setVisibility('');
        setStatus('');
        setIndustry('');
        setReset(false);
      }
    },

    isGridDisplayMode: false,
    itemsFilterBarSort: {
      label: 'Trier par:',
      options: [
        {
          value: 'popular',
          label: 'Populaire'
        },
        {
          value: 'recent',
          label: 'Récent'
        }
      ],
      selectedValue: sortBy,
      setSelectedValue: (sortBy) => {
        if (page > 1) {
          setPage(1);
        }
        setCardPropsData([]);
        setSortBy(sortBy);
      }
    },
    onChange: (e) => {
      if (page > 1) {
        setPage(1);
      }
      setCardPropsData([]);
      setSearchValue(e);
    },
    onClear: () => {
      if (searchValue == '') return;
      if (page > 1) {
        setPage(1);
      }
      setCardPropsData([]);
      setSearchValue('');
    },
    onSearch: (e) => {
      if (searchValue == '') return;
      if (page > 1) {
        setPage(1);
      }
      setCardPropsData([]);
      setSearchValue(e);
    },
    ...(showEshop ? { listButton: {
      handleClick: (showTableEshop) => {
        if (showTableEshop) {
          return;
        }
        setShowGridEshop(false);
        setShowTableEshop(!showTableEshop);
      },
      icon: ListSvg,
      selected: showTableEshop
    },
    gridButton: {
      handleClick: (showGridEshop) => {
        if (showGridEshop) {
          return;
        }
        setShowTableEshop(false);
        setShowGridEshop(!showGridEshop);
      },
      icon: GridSvg,
      selected: showGridEshop
    }, }  
    :
    { listButton: {
      handleClick: (showTable) => {
        if (showTable) {
          return;
        }
        setShowGrid(false);
        setShowTable(!showTable);
      },
      icon: ListSvg,
      selected: showTable
    },
    gridButton: {
      handleClick: (showGrid) => {
        if (showGrid) {
          return;
        }
        setShowTable(false);
        setShowGrid(!showGrid);
      },
      icon: GridSvg,
      selected: showGrid
    },})
   
  };
  const handlecreation = () => {
    if (!nomService || nomService.trim().length == 0) {
      setErrorMessageCrea("Ce champ est requis");
      return;
    }
    if (nomService.length > 0) {
      if (guichetId) {
        postCreateEservice.mutate({
          idGuichet: guichetId,
          nameEservice: nomService,
        },
          {
            onSuccess: () => {
              messageApi.success({
                content: 'Crée avec succès',
                duration: 2.5
              });
            }
          });
      } else {
        console.error('guichetId is not available', guichetId);
      }
      setSelectedElement('');
      setNomService('');
    }
  }
  const handleJoinEservice = () => {
    if (!values || values.length === 0) {
      setErrorMessageJoin("Ce champ est requis");
      console.log('err');
      return;
    }
    if (values.length >= 1) {
      if (selectedCardOption?.id) {
        postRequestJoinEservice.mutate({
          note: note,
          roles: values,
          EserviceId: selectedCardOption.id
        });
      } else {
        console.error('Id is not available');
      }
      setSelectedCardOption(null);
      setNote('');
      setValues([]);
    }
  }
  console.log('test', getCategoriesList);
  const handleNameChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setErrorMessageCrea('');
    const value = e.target.value;
    if (value?.length <= MAX_CARACTER_NAME) {
      setNomService(value);
    }
  }

  const handleCloseJoin = () => {
    setSelectedCardOption(null);
    setNote('');
    setValues([]);
  }

  const onChange = (options: [{ label: string, value: string }]) => {
    console.log(options);
    const valuesArray = options.map(option => option.value);
    setValues(valuesArray);
  }
  useEffect(() => { console.log('selectedCard', selectedCardOption) }, [selectedCardOption])
  useEffect(() => {
    if(roleFiltre || visibility || status || industry ){
      setReset(true)
    }else{
      setReset(false)
    }
    setIsFetching(true);
    setAllEservicesParams((prevData) => ({
      ...prevData,
      
      recent: sortBy.value,
      idGuichet:idGuichet,
      name: searchValue,
      favorite: favorite,
      industry: industry,
      numPage: page,
      contributeur: contribute,
      status: status,
      typePublication: visibility,
      role: roleFiltre,

    }));



  }, [searchValue, page, contribute, favorite, sortBy, industry, status, visibility, roleFiltre,idGuichet]);


  useEffect(() => {
    if (getEserviceList.isFetching) {
      setIsFetching(true);
    }
    if (getEserviceList.isSuccess) {
      const eserviceData = getEserviceList.data.body.data;
      const totalItems = getEserviceList.data.body.pagination.totalLength;

      if (eserviceData) {
        if (totalItems !== undefined) {
          setTotalNumber((prev) => (totalItems !== prev ? totalItems : prev));
        }
        setCardPropsData((prevData) => {
          console.log("eservices", eserviceData)
          return showGrid && page > 1
            ? [...prevData, ...convertToEserviceCard(eserviceData)]
            : convertToEserviceCard(eserviceData);
        });
        setIsFetching(false);
      }

      return;
    }

  }, [getEserviceList.status, allEservicesParams, getEserviceList.fetchStatus])

  useEffect(() => {
    if (selectedCardOption && selectedCardOption?.id) {
      const selectedEservice = cardPropsData.find((eservice) => eservice.id === selectedCardOption.id);
      if (selectedEservice && selectedCardOption.selectedValue === 'param') {
        dispatch(setSelectedEservice(selectedEservice));
        const newPath = `${window.location.pathname}/${selectedCardOption.id}`;
        console.log("path", newPath);
        navigate(newPath);
      }
      if (selectedEservice && selectedCardOption.selectedValue === 'edit') {
        dispatch(setSelectedEservice(selectedEservice));
        const newPath = `/studio/${selectedEservice.guichetId}/${selectedEservice.id}/${selectedEservice.moduleName}`;
        console.log("path", newPath);
        window.location.href = newPath;
      }
      if (selectedEservice && selectedCardOption.selectedValue === 'access') {
        dispatch(setSelectedEservice(selectedEservice));
        const newPath = `/karazal/kas/publish.jsp?id=/${selectedEservice.guichetId}`;
        window.location.href = newPath;
      }
    }

  }, [selectedCardOption]);

  const BreadCrumbItems = [
    { icon: <MaterialIcon center label="home" className="text-base" />, href: `/${profilePathName}` },
    { title: "Guichets", href: `/${profilePathName}/guichets` },
    { title: "e-Services", href: "" }
  ]
useEffect(()=>{console.log("selectedItems",selectedItems)},[selectedItems])
  return (
    <PageContentContainer className="flex flex-col gap-7 p-5 rounded-md">
      {contextHolder}
      <BreadCrumb items={BreadCrumbItems} />
        
          <FilterBar filterBarContent={fBc} filterBarInput={fBi} filterBarSortBy={fBsEserv} />
          {showGrid && !showEshop && (
            <>
              {(cardPropsData.length >= 1 || isFetching) ? (
                <CardGrid
                  isFetching={isFetching}
                  currentPage={page}
                  setFavoriteEservice={setFavoriteRequest}
                  totalPages={Math.ceil(totalNumber / 10)}
                  fetchMoreData={(nextPage: number) => {
                    setPage(nextPage);
                  }}
                  items={cardPropsData}
                  type="eservice"
                  onSelectDropDown={setSelectedCardOption}
                />
              ) : (
                <EmptyData
                  icon={emptyIcon}
                  title={"Aucun e-service pour l'instant"}
                  description={"La liste des e-services créés sera affichée ici"}
                />
              )}
            </>
          )}

          {showTable && !showEshop && (
            cardPropsData.length > 0 || isFetching ? (
              <Table
                isFitching={isFetching}
                setFavoriteEservice={setFavoriteRequest}
                type="eservice"
                setCurrentPage={setPage}
                currentPage={page}
                totalItems={totalNumber}
                onSelectDropDown={setSelectedCardOption}
                headerItems={{
                  items: ['', 'Nom', 'Domaine', 'Rôle', 'Status']
                }}
                rows={cardPropsData}
              />
            ) : (
              <EmptyData
                icon={emptyIcon}
                title={"Aucun e-service pour l'instant"}
                description={"La liste des e-services créés sera affichée ici"}
              />
            )
          )}

          {selectedElemnt === 'scratch' && (
            <Modal
              onClose={() => { setSelectedElement(''); setNomService(''); }}
              title={"Nouveau e-service"}
              leftBtnArgs={{
                label: 'Annuler',
                args: { onClick: () => { setSelectedElement(""); setNomService(''); } },
                color: 'neutral',
                size: 'xs',
                styling: 'outline'
              }}
              rightBtnArgs={{
                label: 'Ajouter',
                args: { onClick: handlecreation },
                color: 'primary',
                size: 'xs',
                styling: 'solid'
              }}
              icon={""}
            >
              <div className="flex flex-col p-6 gap-3">
                <Input
                  errorMessage={errorMessageCrea}
                  size="sm"
                  label="Nom de e-service"
                  helpText={`ID :${cleanAndFormatNameResource(nomService)}`}
                  args={{
                    placeholder: "Entrez le nom de votre e-service",
                    value: nomService,
                    onChange: handleNameChange
                  }}
                  charCounter={MAX_CARACTER_NAME}
                />
                <InputText
                  label="Guichet"
                  size="sm"
                  value={guichetName}
                  disabled
                />
              </div>
            </Modal>
          )}

          {selectedCardOption?.selectedValue === 'rejoindre' && (
            <Modal
              onClose={handleCloseJoin}
              title={"Demande d’accès"}
              leftBtnArgs={{
                label: 'Annuler',
                args: { onClick: handleCloseJoin },
                color: 'neutral',
                size: 'xs',
                styling: 'outline'
              }}
              rightBtnArgs={{
                label: 'Ajouter',
                args: { onClick: handleJoinEservice },
                color: 'primary',
                size: 'xs',
                styling: 'solid'
              }}
              icon={""}
            >
              <div className="flex flex-col p-6 gap-3">
                <span className={styles.textRequest}>Veuillez choisir le rôle que vous souhaitez occuper dans l&apos;e-service</span>
                <div className={styles.labelRole}>Rôle</div>
                <MultipleAdvancedDropDownList
                  list={filteredRoleList}
                  selectedValues={values}
                  addNew={null}
                  errorMessage={errorMessageJoin}
                  handlerChange={onChange}
                  size={3}
                  label={undefined}
                />
                <TextArea
                  size="sm"
                  args={{
                    placeholder: "Ajouter une note...",
                    value: note,
                    onChange: (e) => { setNote(e.target.value); }
                  }}
                  label="Note"
                />
              </div>
            </Modal>
          )}
          { showEshop && <Modal  
                            onClose={handleCloseEshop}
                            width="950px"
                            title={"E-services"}
                            leftBtnArgs={{
                              label: 'Annuler',
                              args: { onClick: handleCloseEshop },
                              color: 'neutral',
                              size: 'xs',
                              styling: 'outline'
                            }}
                            rightBtnArgs={{
                              label: 'valider',
                              args: { onClick: handleJoinEservice },
                              color: 'primary',
                              size: 'xs',
                              styling: 'solid'
                            }}
                            icon={""}  >
                              <div className="flex flex-col gap-5 p-5 h-[30rem]">

                            <FilterBar className="w-[90%]"  filterBarInput={fBi} filterBarSortBy={fBsEserv} />
                            {showGridEshop && (
            <>
              {(cardPropsData.length >= 1 || isFetching) ? (
                <CardGrid
                  isFetching={isFetching}
                  currentPage={page}
                  totalPages={Math.ceil(totalNumber / 10)}
                  fetchMoreData={(nextPage: number) => {
                    setPage(nextPage);
                  }}
                  items={cardPropsData}
                  type="selectable"
                  onSelectDropDown={setSelectedCardOption}
                  selectedItems={selectedItems}
                  setSelectedItems={setSelectedItems}
                />
              ) : (
                <EmptyData
                  icon={emptyIcon}
                  title={"Aucun e-service pour l'instant"}
                  description={"La liste des e-services créés sera affichée ici"}
                />
              )}
            </>
          )}

          {showTableEshop && (
            cardPropsData.length > 0 || isFetching ? (
              <Table
                isFitching={isFetching}
                setFavoriteEservice={setFavoriteRequest}
                type='selectable'
                setCurrentPage={setPage}
                currentPage={page}
                totalItems={totalNumber}
                headerItems={{
                  items: ['', 'Nom', 'Source', 'Catégorie']
                }}
                rows={cardPropsData}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
              />
            ) : (
              <EmptyData
                icon={emptyIcon}
                title={"Aucun e-service pour l'instant"}
                description={"La liste des e-services créés sera affichée ici"}
              />
            )
          )}

                            </div>
                            </Modal>
                          }
        
      

    </PageContentContainer>
  );



}
