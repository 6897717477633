import {FC} from 'react';
import styles from './menuItem.module.css';
import { UserProfile } from '../../../../shared/types';
import {ProfileCard} from "../profile/ProfileCard";
import classNames from "classnames";
import {useHandleComponentVisibility} from "../../../../shared/hooks";
import { AvatarComponent } from '../../../../shared/components/avatar/AvatarComponent';

interface UserTopMenuItemProps extends UserProfile {
    signOut:()=>void;
}
const UserTopMenuItem: FC<UserTopMenuItemProps> = (props) => {
    const {isOpen,setOpenStatus,ref}=useHandleComponentVisibility()
    const profileCardStyle=classNames({
        ["transition-opacity duration-200 min-w-[200px]"]:true,
        ["show"]: isOpen,
        ["notDisplayV2"]:!isOpen
    })
    return (
      <div className="relative" ref={ref}>
          <div className={styles.userMenuItem} onClick={()=>setOpenStatus(true)}>
              {props.avatarUrl ?  <img className={styles.userMenuItemImage}
                   src={props.avatarUrl} alt=""/> :  <AvatarComponent fullname={props.fullName || ''} />}
              <span className="truncate sm:hidden md:flex lg:flex">{props.fullName}</span>
          </div>
          <ProfileCard className={profileCardStyle} imageUrl={props.avatarUrl} name={props.fullName} email={props.email} signOut={props.signOut}/>
      </div>
  );
};
export default UserTopMenuItem;
