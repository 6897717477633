import { FocusEvent, forwardRef, ForwardRefRenderFunction, useRef, useState } from "react";
import globalStyles from "../input.module.css"
import customStyles from "./input-text.module.css"
import classNames from "classnames";
import { InputTextProps } from "../../../types";
import { MaterialIcon } from "../../icons";


const ForwardedInputText: ForwardRefRenderFunction<HTMLInputElement, InputTextProps> = (props, ref) => {
    const {
        icon,
        size = "sm",
        label,
        withError = false,
        disabled = false,
        onlyRead = false,
        clearable,
        onClick,
        errorMessage,
        beforeText = (<></>),
        afterText = (<></>),
        text = false,
        removeRightRadius = false,
        removeLeftRadius = false,
        isTag = false,
        hideLabel=false,
        ...inputProps } = props;

    const [isFocused, setIsFocused] = useState<boolean>(false);
    const getErrorMessage = errorMessage ?? "";
    const inputRef = ref ?? useRef<HTMLInputElement>(null);
    const rootStyle = classNames({
        [globalStyles.rootContainer]: true,
        [globalStyles["portal-input-css-var"]]: true,

    })
    const inputContainerStyle = classNames({
        [globalStyles.inputContainer]: true,
        [globalStyles.focusedInput]: isFocused,
        [globalStyles.disabledInput]: disabled,
        [globalStyles.readOnlyInput]: inputProps.readOnly,
        [globalStyles.onlyReadInput]: onlyRead,
        [globalStyles.errorInput]: props.errorMessage,
        [globalStyles.tagInput]: isTag,
        ["h-8 px-2 "]: size === "sm",
        ["h-9 px-2.5"]: size === "md",
        ["h-10 px-3"]: size === "lg",
        ["h-[70px] px-3"]: size === "xl",
        ["rounded-r "]: size === "sm" && !removeRightRadius,
        ["rounded-r-lg"]: ["md", "lg","xl"].includes(size) && !removeRightRadius,
        ["rounded-l "]: size === "sm" && !removeLeftRadius,
        ["rounded-l-lg"]: ["md", "lg", "xl"].includes(size) && !removeLeftRadius,
    })
    const inputStyle = classNames({
        ["text-xs"]: size === "sm",
        ["text-[15px]"]:size === "xl",
        ["text-[13px]"]: ["lg", "md"].includes(size),
        ["!cursor-pointer"]: onlyRead,
        [customStyles.input]: true,
    })
    const inputLabelStyle = classNames({
        [globalStyles.inputLabel]: true,
        ["invisible"]:hideLabel,
        ["text-xs"]: size === "sm",
        ["text-[15px]"]:size === "xl",
        ["text-sm"]: ["lg", "md"].includes(size),
    })
    const footerStyles = classNames({
        ["transition-all duration-300 "]: true,
        ["show"]: errorMessage,
        ["notShow"]: !errorMessage,
        ["h-3"]: withError
    })
    const renderInputStyle = classNames({
        [" w-full flex "]: true,
        [" items-center"]: !isTag,
        [" gap-2"]: !isTag || (isTag && beforeText != ''),
        [" flex-col"]: isTag,

    })


    const handleInputBlur = (e: FocusEvent<HTMLInputElement>) => {
        setIsFocused(false);
        inputProps.onBlur?.(e)
    }
    const handleInputFocus = (e: FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        inputProps.onFocus?.(e)
    }
    const handleClearInput = () => {
        if (inputRef && typeof inputRef !== 'function' && inputRef.current) inputRef.current.value = '';
        if (props.onClear) props.onClear();
    }


    const renderLabel = () => {
        if (label ) return (
            <div className="flex justify-between w-full ">
                <span className={inputLabelStyle}>{label}</span>
                <span></span>
            </div>)
    }
    const renderInput = () => {
        if (!text) return (
            <input
                ref={inputRef}
                className={inputStyle}
                readOnly={onlyRead}
                {...inputProps}
                onBlur={handleInputBlur}
                onFocus={handleInputFocus}
            />
        )
        return (<></>)
    }
    const renderClearIcon = () => {
        if (clearable) return (<MaterialIcon onClick={handleClearInput} label="close"
            className="text-base text-slate-400 hover:text-slate-500 cursor-pointer" />)
    }
    const renderIcons = () => {
        if (icon || clearable) return (
            <div className="flex justify-center items-center gap-1">
                {renderClearIcon()}
                {icon}
            </div>)
    }
    const renderFooter = () => {
        if (withError) return (<span className="text-red-600 text-xs">{getErrorMessage}</span>)
        if (props.helpText) return (<span>{props.helpText}</span>)
        return (<></>)
    }


    return (
        <div className={rootStyle}>
            {renderLabel()}
            <div className="w-full">
                <div className={inputContainerStyle} onClick={onClick}>
                    <div className={renderInputStyle}>
                        {beforeText}
                        {renderInput()}
                    </div>
                    <div className="flex gap-2 justify-center items-center">
                        {afterText}
                        {renderIcons()}
                    </div>

                </div>
                <div className={footerStyles}>
                    {renderFooter()}
                </div>
            </div>
        </div>
    )
}
export const InputText = forwardRef(ForwardedInputText)