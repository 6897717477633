import styles from './landing.module.css';
import facebookLogo from '../../../../shared/assets/icons/facebook.svg';
import linkedinLogo from '../../../../shared/assets/icons/linkedin.svg';
import instagramLogo from '../../../../shared/assets/icons/instagram.svg';


export const FooterComponent = () => {
    return (
      <footer className={styles.footer}>
        <div className={styles.footerLeft}>
          <img src="/pictures/ksw-white.svg" alt="Logo" className={styles.footerLogo} />
          <p className={styles.footerMadeby}>&copy;2024, Made with &#10084; by Ribatis
          </p>
        </div>
  
        <div className={styles.footerLinks}>
          <div className={styles.footerColumn}>
            <h4>Services</h4>
            <a href="">Référentiel e-services</a>
            <a href="">Guichets uniques</a>
            <a href="">Suivi dossier</a>
          </div>
          <div className={styles.footerColumn}>
            <h4>Liens rapides</h4>
            <a href="">Qui sommes-nous</a>
            <a href="">Découvrir comment ça marche </a>
            <a href="">FAQ </a>
          </div>
          <div className={styles.footerColumn}>
            <h4>Partenaire
            </h4>
            <a href="">Offre</a>
            <a href="">Produits</a>
            <a href="">Fonctionnalités</a>
          </div>
          <div className={styles.footerColumn}>
            <h4>Support</h4>
            <a href="">Guides et Tutoriels </a>
            <a href="">Base de connaissance  </a>
            <a href="">Communauté</a>
            <a href="">Contacter le support   </a>
          </div>
        </div>
  
        <div className={styles.footerSocial}>
        <a href="https://www.linkedin.com/company/ribatis" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
          <img src={linkedinLogo}/>

          </a>
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <img src={facebookLogo}/>
          </a>
          <a href="https://www.instagram.com/_ribatis/" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <img src={instagramLogo}/>
          </a>
        </div>
      </footer>
    );
  };
  
export default FooterComponent;