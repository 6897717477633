import { InputText } from '../../../../shared/components';
import { MaterialIcon } from '../../../../shared/components/icons';
import styles from './catalogue.module.css';

export const CatalogComponent=()=>{
const  search= <MaterialIcon label="search" className='text-slate-400'/>;
const close=<MaterialIcon label="close" className='text-slate-400'/>;

return(<>
<div className={styles.catalogContainer}>
    <span className={styles.titleCatalogue}>Catalogue e-services</span>
    <div className='flex gap-5 w-3/5'>  
        <InputText
          icon={search}
          size='lg'
          placeholder="Mot clé"
          afterText={close}
        /> 
        <div className={styles.iconContainer}><MaterialIcon label='grid_view' className='text-slate-500 active:text-blue-600  dark:text-slate-400 dark:active:text-white '/></div>
        <div className={styles.iconContainer}><MaterialIcon label='format_list_bulleted' className='text-slate-500 active:text-blue-600 dark:text-slate-400 dark:active:text-white'/></div>

        </div>
</div>
</>);
}