import { BreadCrumb, Tabs } from "../../../../../../shared/components";
import { FC, useState } from "react";
import styles from './eservice.param.module.css';
import { PageContentContainer } from "../../../../layouts";
import { EServiceInfoTab } from "./tabs/EserviceInfoTab";
import { EserviceMembresTab } from "./tabs/EserviceMembresTab";
import {  EserviceContributeursTab } from "./tabs/EserviceContributeursTab";
import { EserviceUsersTab } from "./tabs/EserviceUsersTab";
import { MaterialIcon } from "../../../../../../shared/components/icons";
import { useHandleDomLocation } from "../../../../hooks";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../shared/stores/store";

export const EServiceParamsPage: FC = () => {
  const [selectedTab] = useState("eService");
  const { profilePathName } = useHandleDomLocation();
  const { guichetId } = useParams<{ guichetId: string }>();
  const selectedEservice = useSelector((state: RootState) => state.eservices.selectedEservice);

  const BreadCrumbItems = [
    { icon: <MaterialIcon center label="home" className="text-base" />, href: `/${profilePathName}/guichets/${guichetId}` },
    { title: "e-Services", href: `/${profilePathName}/guichets/${guichetId}/eservices` },
    { title: selectedEservice.name || '', href: `/${profilePathName}/guichets/${guichetId}/eservices` }

      ]
  const tabList = [
    { name: 'eService', label: <><span className="icon">bubble_chart</span>E-service</>, children: <EServiceInfoTab /> },
    { name: 'modules', label: <><span className="icon">apps</span>Modules</>, children: <div></div> },
    { name: 'schemaRelational', label: <><span className="icon">schema</span>Schéma relationnel</>, children: <div></div> },
    { name: 'membres', label: <><span className="icon">manage_accounts</span>Membres</>, children: <EserviceMembresTab /> },
    { name: 'contributeurs', label: <><span className="icon">work_outline</span>Contributeurs</>, children: <EserviceContributeursTab/> },
    { name: 'user', label: <><span className="icon">group</span>Utilisateurs</>, children: <EserviceUsersTab/> },
    { name: 'role', label: <><span className="icon">workspaces</span>Rôles</>, children: <div></div> },
    { name: 'menu', label: <><span className="icon">menu</span>Menus</>, children: <div></div> },
  ];
  return (
    <PageContentContainer className="flex flex-col gap-1 p-5 !h-[100%]">
    <BreadCrumb items={BreadCrumbItems} ></BreadCrumb>
      
      <div className={styles.tabsContainer}>
        <Tabs tabs={tabList} align="horizontal"  current={selectedTab} defSelected={"serviceSettings"} />
      </div>
    </PageContentContainer>
  );
};

