import { karazActionResponseAsMap, mapToUrlSearchParams } from '../mapper';
import {ApiJaxRsResponse, ApiRemoteAction, GenericResponse, GenericResponseWrapper} from '../../types';
import { HttpMethods, ResponseResult } from '../enums';
import { getUserProfile } from './local-storage.helper';

export const postGenericKarazActionRequest = async <T extends object, R>(
  data: T,
  karazPath: ApiRemoteAction,
  responseType: ResponseResult
): Promise<R> => {
  const formData: URLSearchParams = mapToUrlSearchParams(data);
  Object.keys(karazPath).forEach((karazPathKey) => {
    formData.append(
      karazPathKey,
      karazPath[karazPathKey as keyof ApiRemoteAction]
    );
  });
  if (responseType === ResponseResult.JSON)
    return await postFormAsJson(formData, karazPath.path);
  return (await postFormAsMapResponse(formData, karazPath.path)) as R;
};

export const fetchGenericJaxRsRequestFromFormData = async <
  T extends object
>(
  data: T | undefined,
  apiPath: ApiJaxRsResponse,
  method: HttpMethods
) => {
  let formData: URLSearchParams = new URLSearchParams();
  if (data) formData = mapToUrlSearchParams(data);
  const userId = getUserProfile()?.userId ?? '';
  formData.append('userId', userId);
  Object.keys(apiPath).forEach((ApiPathKey) => {
    formData.append(ApiPathKey, apiPath[ApiPathKey as keyof ApiJaxRsResponse]);
  });

  return fetch(apiPath.path, {
    method,
    body: formData
  }).then((result) => result.json());
};

export const fetchGenericJaxRsRequestFromJson = async <T extends object>(
  data: T | undefined,
  apiPath: ApiJaxRsResponse,
  method: HttpMethods
) => {
  const userId = getUserProfile()?.userId ?? '';

  const headers = new Headers({
    'userId': userId,
    'apiKey':apiPath.apiKey,
  });
  const requestData = { ...data, ...apiPath, userId };

  return fetch(apiPath.path, {
    method,
    headers,
    body: JSON.stringify(requestData),
  }).then((result) => result.json());
};

export const postFormAsMapResponse = async <R>(
  form: URLSearchParams,
  url: string
): Promise<R> => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: form
  })
    .then((r) => r.text())
    .then((data) => karazActionResponseAsMap(data) as R);
};

export const postFormAsJson = async <R>(
  form: URLSearchParams,
  url: string
): Promise<R> => {
  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: form
  }).then((r) => r.json());
};

export const transformGenericResponseWrapperResponse = <T>(
    data: GenericResponseWrapper<T>,
    customFormatter: ((body: T) => Partial<T>) | undefined = undefined
): GenericResponse<T> => {
 if(customFormatter) return { ...data.data,...customFormatter(data.data.body)}
  return data.data
};