import {AdvancedUploader, FilesListViewer} from "../../../../../../../shared/components";
import {useState} from "react";
import {useGetCheckVerificationOrganizationStatus} from "../../../../../api";
import {useHandleDomLocation} from "../../../../../hooks";

export const OrganizationVerificationTab=()=>{
    const {windowId=""} =useHandleDomLocation();
    const [uploadedFiles,setUploadedFiles]=useState<File[]>([]);
    const getCheckVerificationOrganizationStatus=useGetCheckVerificationOrganizationStatus(windowId)
    const handleRemoveFile=(index:number)=>{
        console.log("ss")
        const remainedFiles=uploadedFiles.filter((_file,key)=>(index!==key))
        setUploadedFiles(remainedFiles);
    }
    console.log("cc",getCheckVerificationOrganizationStatus);
    return (
        <div>
            <span className="font-semibold text-sm text-blue-700">Pièces justificatives</span>
            <AdvancedUploader  title="Faites glisser ou déposez vos pièces justificatives ici"
                              description="Formats pris en charge : PDF, PNG, JPEG" onUploadFiles={(files)=>setUploadedFiles([...uploadedFiles,...files])}/>
            <FilesListViewer files={uploadedFiles} onRemoveFile={handleRemoveFile}/>
        </div>
    )
}