import React, { useState } from 'react';
import ServiceCard from '../../../../../components/ui/cards/eservice-card/ServiceCard';
import {
  FilterBarInput,
  FilterBarInputProps
} from '../../../../../components/ui/filter-bar/FilterBarInput';
import FilterSvg from '../../../../../../../shared/components/icons/FilterSvg';

export const EservicesMarketplaceTab = () => {
  const [searchValue] = useState<string>('');
  const filters = [
    'Tous',
    'Aéronautique',
    'Éducation',
    'Logistique',
    'Gouvernement',
    'Commerce',
    'Fabrication',
    'Pharma'
  ];

  const handleFilterSelect = (filter: string) => {
    console.log(`Selected filter: ${filter}`);
  };
  const services = [
    {
      icon: 'location_home',
      service: 'Autorisation des activités économiques',
      organization: 'Royal Air Maroc',
      orgImgPath: '/pictures/example-air-royale.png',
      cost: 'Payant',
      isFree: false,
      isVerified: false,
      link: '#'
    },
    {
      icon: 'school',
      service: 'Gestion diplôme',
      organization: 'UM6P',
      orgImgPath: '/pictures/example-air-royale.png',
      cost: '1000 MAD',
      isFree: false,
      isVerified: false,
      link: '#'
    },
    {
      icon: 'school',
      service: 'Gestion diplôme',
      organization: 'UM6P',
      orgImgPath: '/pictures/example-air-royale.png',
      cost: '1000 MAD',
      isFree: false,
      isVerified: false,
      link: '#'
    },
    {
      icon: 'school',
      service: 'Gestion diplôme',
      organization: 'UM6P',
      orgImgPath: '/pictures/example-air-royale.png',
      cost: '1000 MAD',
      isFree: false,
      isVerified: false,
      link: '#'
    },
    {
      icon: 'school',
      service: 'Gestion diplôme',
      organization: 'UM6P',
      orgImgPath: '/pictures/example-air-royale.png',
      cost: '1000 MAD',
      isFree: false,
      isVerified: false,
      link: '#'
    },
    {
      icon: 'school',
      service: 'Gestion diplôme',
      organization: 'UM6P',
      orgImgPath: '/pictures/example-air-royale.png',
      cost: '1000 MAD',
      isFree: false,
      isVerified: false,
      link: '#'
    },
    {
      icon: 'location_home',
      service: 'Réservation de vols',
      organization: 'Royal Air Maroc',
      orgImgPath: '/pictures/example-air-royale.png',
      cost: 'Gratuit',
      isFree: true,
      isVerified: true,
      link: '#'
    }
  ];
  const fBi: FilterBarInputProps = {
    filterBarInputId: 'search',
    filterBarInputPlaceHolder: 'Rechercher...',
    filterBarInputType: 'text',
    filterBarInputValue: searchValue,
    filterButton: {
      handleClick: () => {},
      icon: FilterSvg,
      label: 'Filtres',
      selected: false,
      resetClick: () => {}
    },
    isGridDisplayMode: false,
    itemsFilterBarSort: {
      label: 'Trier par:',
      options: [
        {
          value: 'popular',
          label: 'Populaire'
        },
        {
          value: 'recent',
          label: 'Récent'
        }
      ],
      selectedValue: {
        value: 'recent',
        label: 'Récent'
      },
      setSelectedValue: () => {}
    },
    onChange: () => {},
    onClear: () => {},
    onSearch: () => {}
  };
  return (
    <div className="flex overflow-hidden flex-col gap-7">
      <FilterButtonGroup
        filters={filters}
        onFilterSelect={handleFilterSelect}
      />
      <div className="flex flex-col gap-4 px-1.5">
        <FilterBarInput {...fBi} />
        <p className="text-xs text-gray-800">
          {services.length} e-services disponibles
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 overflow-y-auto h-[100%]">
          {services.map((service, index) => (
            <ServiceCard
              key={index}
              icon={service.icon}
              service={service.service}
              isVerified={service.isVerified}
              orgImgPath={service.orgImgPath}
              organization={service.organization}
              cost={service.cost}
              isFree={service.isFree}
              link={service.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
interface FilterButtonGroupProps {
  filters: string[];
  onFilterSelect: (filter: string) => void;
}

const FilterButtonGroup: React.FC<FilterButtonGroupProps> = ({
  filters,
  onFilterSelect
}) => {
  const [selectedFilter, setSelectedFilter] = useState<string>('Tous');

  const handleFilterClick = (filter: string) => {
    setSelectedFilter(filter);
    onFilterSelect(filter);
  };

  return (
    <div className="flex gap-1">
      {filters.map((filter) => (
        <button
          key={filter}
          onClick={() => handleFilterClick(filter)}
          className={`px-4 py-2 rounded-full text-xs ${
            selectedFilter === filter
              ? 'bg-blue-500 text-white border border-blue-600'
              : 'bg-white text-slate-800 border border-slate-200 transition duration-200 ease-in-out hover:bg-slate-50'
          } `}>
          {filter}
        </button>
      ))}
    </div>
  );
};

export default FilterButtonGroup;
