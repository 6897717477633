import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Main from '../pages/Main';
import UserRoutes from './UserRoutes';
import OrganisationRoutes from './GuichetRoutes';
import { OnboardingPage } from '../pages';
import { ForgetPassword, Login, SignUp } from '../../auth/pages';
import { AuthProvider } from '../../../shared/context';
import { LandingPage } from '../../auth/pages/landing-page/LandingPage';
import { CatalogueEservicesPage } from '../../auth/pages/catalogue-eservices-page/CatalogueEservicesPage';

const MainRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path={'landing'} element={<LandingPage/>}/>
      <Route path={'catalogue'} element={<CatalogueEservicesPage/>}/>
      <Route path={'onboarding'} element={<OnboardingPage />} />
      <Route path={'signup'} element={<SignUp />} />
      <Route path={'authentication'} element={<Login />} />
      <Route path={'forgetpassword'} element={<ForgetPassword />} />
      <Route path="/*" element={<AuthProvider><Main /></AuthProvider>}>
        <Route index element={<Navigate to="utilisateur" replace />} />
        <Route
          path="guichet/*"
          element={
            <AuthProvider>
              <OrganisationRoutes />
            </AuthProvider>
          }
        />
        <Route
          path="utilisateur/*"
          element={
            <AuthProvider>
              <UserRoutes />
            </AuthProvider>
          }
        />
      </Route>
    </Routes>
  );
};

export default MainRoutes;
