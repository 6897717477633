import React from 'react';
import styles from './button.module.css';
import classNames from "classnames";
interface ButtonProps {
    label?: string;
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    disabled?: boolean;
    size: "sm" | "md" | "lg";
    color: "primary" | "neutral" | "success" | "error" |"secondary";
    styling: "solid" | "outline" | "ghost" ;
    onClick?: () => void;
}


const Button = (props: ButtonProps) => {


    const {
        label,
        rightIcon,
        styling,
        color,
        size,
        leftIcon,
        disabled,
        onClick=()=>{},
    } = props;

   const rootStyles=classNames({
     [styles.rootContainer]:true,
     [styles[styling]]:true,
     [styles[color]]:true,
     "opacity-30":disabled,
     "gap-0.5 py-[7px] px-[8px] text-[12px]":["sm"].includes(size),
     "gap-1 py-[8.5px] px-[12px] text-[13px]":["md"].includes(size),
     "gap-1 py-[10.5px] px-[12px] text-[13px]":["lg"].includes(size),
 });

 const renderBeforeText=()=>{
    if(leftIcon) return {leftIcon}
    return <></>
}
const renderAfterText=()=>{
        if(rightIcon) return {rightIcon}
        return <></>
    }
const renderLabel=()=>{
    if (label) return <span className="flex items-center justify-center">{label}</span>
}

    return (
        <button className={rootStyles} onClick={onClick}>
            <>
                {renderBeforeText()}
                {renderLabel()}
                {renderAfterText()}
            </>
        </button>
    );

}

export { Button };
export type { ButtonProps };
