import {DynamicListData, GenericResponse, GenericResponseWrapper } from "../../../../shared/types";
import { PageResponse } from "../../../../shared/types/api.type";
import { HttpMethods } from "../../../../shared/utils/enums";
import {
    fetchGenericJaxRsRequestFromFormData,
    fetchGenericJaxRsRequestFromJson,
} from "../../../../shared/utils/helpers";
import { EserviceCreationRequest, EserviceData, ListEserviceRequest,EserviceJoinRequest, ListRequestJoinEserviceData, RequestJoinEservice, EserviceAcceptJoin, FavoriteEserviceRequest, EserviceMembersListRequest, EserviceMembersListResponse } from "../../types";
import { ACCEPT_REQUEST_JOIN_API_PATH, ADD_FAVORITE_ESERVICE_API_PATH, CATEGORIES_LIST_API_PATH, ESERVICE_CREATION_API_PATH, ESERVICE_LIST_API_PATH,ESERVICE_LIST_REQUEST_API_PATH,ESERVICE_MEMBERS_LIST_API_PATH,REFUSE_REQUEST_JOIN_API_PATH,REQUEST_JOIN_ESERVICE_API_PATH } from "./apiPath";

export const getEservicesList = (data:ListEserviceRequest): Promise<GenericResponse<PageResponse<EserviceData[]>>> => {
    return fetchGenericJaxRsRequestFromFormData(data, ESERVICE_LIST_API_PATH, HttpMethods.POST);
  };


  export const getRequestJoinEservicesList = (data:RequestJoinEservice): Promise<GenericResponse<PageResponse<ListRequestJoinEserviceData[]>>> => {
    return fetchGenericJaxRsRequestFromJson(data, ESERVICE_LIST_REQUEST_API_PATH, HttpMethods.POST);
  };

  export const getCategoriesList=():Promise<GenericResponse<DynamicListData[]>> =>{
    return fetchGenericJaxRsRequestFromFormData(undefined,CATEGORIES_LIST_API_PATH,HttpMethods.POST);
}

export const getEserviceMembersList = (data:EserviceMembersListRequest): Promise<GenericResponse<PageResponse<EserviceMembersListResponse[]>>> => {
    return fetchGenericJaxRsRequestFromFormData(data, ESERVICE_MEMBERS_LIST_API_PATH, HttpMethods.POST);
  };


export const addFavoriteEservice = (data: FavoriteEserviceRequest): Promise<GenericResponse<null>> => {
    return fetchGenericJaxRsRequestFromFormData(data,ADD_FAVORITE_ESERVICE_API_PATH,HttpMethods.POST);
  };

export const sendInvitationToJoinEservice=()=>{
    return fetchGenericJaxRsRequestFromJson(undefined,ADD_FAVORITE_ESERVICE_API_PATH,HttpMethods.POST);
}






  export const postCreateEservice=(data:EserviceCreationRequest):Promise<GenericResponseWrapper<null>> =>{
    return fetchGenericJaxRsRequestFromFormData(data,ESERVICE_CREATION_API_PATH,HttpMethods.POST);
}

export const postRequestJoinEservice=(data:EserviceJoinRequest):Promise<GenericResponseWrapper<null>> =>{
    return fetchGenericJaxRsRequestFromJson(data,REQUEST_JOIN_ESERVICE_API_PATH,HttpMethods.POST);
}

export const postAcceptRequestJoinEservice=(data:EserviceAcceptJoin):Promise<GenericResponseWrapper<null>> =>{
    return fetchGenericJaxRsRequestFromJson(data,ACCEPT_REQUEST_JOIN_API_PATH,HttpMethods.POST);
}

export const postRefuseRequestJoinEservice=(data:EserviceAcceptJoin):Promise<GenericResponseWrapper<null>> =>{
    return fetchGenericJaxRsRequestFromJson(data,REFUSE_REQUEST_JOIN_API_PATH,HttpMethods.POST);
}

