import {ApiJaxRsResponse} from "../../../../shared/types";

export const CREATE_ORGANIZATION_API_PATH:ApiJaxRsResponse ={
    path:"/karazal/ow-kls-extrat/appmanager/createOrganismParent",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6"
}
export const ALL_WINDOW_API_PATH: ApiJaxRsResponse = {
    path: '/karazal/ow-kls-extrat/appmanager/allGuichets',
    apiKey: 'AB90G-BH903-W4EE1-Z66Q9-907E6'
};
export const ALL_ORGANIZATIONS_API_PATH: ApiJaxRsResponse = {
    path: '/karazal/ow-kls-extrat/appmanager/allGuichets',
    apiKey: 'AB90G-BH903-W4EE1-Z66Q9-907E6'
};
export const REQUEST_ASSOCIATE_ORGANIZATION_API_PATH:ApiJaxRsResponse={
    path: '/karazal/ow-kls-extrat/appmanager/JoinOrganism',
    apiKey: 'AB90G-BH903-W4EE1-Z66Q9-907E6'
}
export const GET_ORGANIZATIONS_BY_WINDOW_API_PATH:ApiJaxRsResponse={
    path: '/karazal/ow-kls-extrat/appmanager/getOrganismByGuichet',
    apiKey: 'AB90G-BH903-W4EE1-Z66Q9-907E6'
}
export const GET_WINDOW_ORGANIZATION_STATUS_API_PATH:ApiJaxRsResponse={
    path: '/karazal/ow-kls-extrat/appmanager/checkWindowStatus',
    apiKey: 'AB90G-BH903-W4EE1-Z66Q9-907E6'
}
export const CANCEL_JOINING_ORGANIZATION_REQUEST_API_PATH:ApiJaxRsResponse = {
    path: '/karazal/ow-kls-extrat/appmanager/deleteJoinOrganism',
    apiKey: 'AB90G-BH903-W4EE1-Z66Q9-907E6'
}
export const GET_WINDOW_ORGANIZATION_ASSOCIATION_REQUESTS_API_PATH:ApiJaxRsResponse={
    path:"/karazal/ow-kls-extrat/appmanager/listGuichetByOrganism",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6",
}
export const GET_VERIFICATION_ORGANIZATION_STATUS_API_PATH:ApiJaxRsResponse = {
    path:"/mockoon/checkVerificationOrganizationStatus",
    apiKey: "AB90G-BH903-W4EE1-Z66Q9-907E6",
}