import {FC, useEffect, useState} from "react";
import classNames from "classnames";
import {DocumentCard} from "../upload-cards/DocumentCard";
import {Pagination} from "kls-ui";

const PAGINATION_ITEMS_PER_PAGE = 3;
const PAGINATION_MAX_PAGES=3;

interface  FilesListViewerProps {
    files:File[];
    onRemoveFile:(index:number)=>void
}
export const FilesListViewer:FC<FilesListViewerProps>=(props)=>{
    const [files,setFiles]=useState<File[]>([]);
    const [currentPage,setCurrentPage]=useState<number>(0);
    const handleRemoveFile=(index:number) => {
       // const filteredFiles=files.filter((_file,key)=>key!==index);
        props.onRemoveFile(index);
       // setFiles(filteredFiles);
    }
    const handlePageChange =(currentPage:number)=>{
        setCurrentPage(currentPage-1);
    }
    const paginationStyle=classNames({
        ["transition-all duration-500"]:true,
        ["notShow"]:files.length<=PAGINATION_ITEMS_PER_PAGE,
        ["show"]:files.length>PAGINATION_ITEMS_PER_PAGE,
    })
    useEffect(() => {
       if (props.files) setFiles(props.files);
    }, [props.files]);
    return (
        <>
            <div className={"flex flex-col gap-3 py-3 min-h-[200px]"}>
                {files && files
                    .filter((_file,index)=>(currentPage*PAGINATION_ITEMS_PER_PAGE<= index && index <(currentPage+1)*PAGINATION_ITEMS_PER_PAGE))
                    .map((file,index)=>(<DocumentCard key={index} title={file.name} size={`${file.size} bytes`} onViewClick={()=>{}} onRemoveClick={()=>handleRemoveFile(index)}/>))}
            </div>
            <div className={paginationStyle}>
                <Pagination  totalItems={files.length} itemsPerPage={PAGINATION_ITEMS_PER_PAGE} maxPage={PAGINATION_MAX_PAGES} handleChange={handlePageChange}  />
            </div>
        </>
    )
}

