import {BreadCrumb, Tabs} from "../../../../../shared/components";
import { MaterialIcon } from "../../../../../shared/components/icons";
import { useHandleDomLocation } from "../../../hooks";
import {PageContentContainer} from "../../../layouts";
import { EservicesMarketplaceTab } from "./tabs/eservices-marketplace-tab/EservicesMarketplaceTab";

export const WindowMarketPlace =()=>{
    const { profilePathName } = useHandleDomLocation();
    const tabs = [
        { name: 'eServices',
          label:
              <div className="flex items-center">
                  <span>E-services</span>
              </div>,
          children: <EservicesMarketplaceTab/>
        },
        {
          name: 'composants',
          label:
              <div  className="flex items-center">
                  <span>Composants métiers</span>
              </div>,
          children: <div></div> },
    ];
    const BreadCrumbItems = [
        {
          icon: <MaterialIcon center label="home" className="text-base" />,
          href: `/${profilePathName}`
        },
        { title: 'Marketplace', href: '' }
      ];
    return (
        <PageContentContainer  className="px-2.5 py-6 flex flex-col gap-3">
            <BreadCrumb items={BreadCrumbItems} />
            <Tabs tabs={tabs} overflow/>
            {/* <EserviceDetails/> */}
        </PageContentContainer>)
}