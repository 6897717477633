import { Button } from '../../../../shared/components';
import styles from './landing.module.css'
import { useNavigate } from 'react-router-dom';


export const KampusComponent =()=>{
    const navigate = useNavigate();
    return <><div className="flex gap-7 p-20 dark:bg-slate-800">
        <div className="flex flex-col gap-10">
            <span className={styles.titleKampus}>Kampus : Un Hub Technologique au service de l’innovation</span>
            <span className={styles.textKampus}>Créez vos propres services sans compétences techniques grâce aux outils intuitifs Karaz, collaborez avec d’autres partenaires, et formez-vous pour développer des solutions innovantes, quel que soit votre profil.</span>
            <div className='w-fit'>
                <Button size={'lg'} color={'secondary'} styling={"solid"}  label="Votre parcours Digital commence ici !" />
            </div>
        </div>
        <div className={styles.imageContainer}>
            <img src='/pictures/rafiki-1.svg'></img>
            <img src='/pictures/rafiki-2.svg'></img>
            <img src='/pictures/rafiki-3.svg'></img>
            <img src='/pictures/rafiki-4.svg'></img>
        </div>

    </div>
    <div className={styles.lanceExpContainer}>
    <span className={styles.lanceExpText}>Lancer votre expérience Karaz dès maintenant !</span>
    <div className='w-fit'>
                <Button size={'lg'} color={"secondary"} styling={"solid"}  label="Créez votre compte" onClick={()=>navigate("/signup")} />
            </div>
    </div>
</>}