import React from 'react';
import { MaterialIcon } from '../../../../../../shared/components/icons';
import { Link } from 'react-router-dom';

interface ServiceCardProps {
  icon: string;
  service: string;
  organization: string;
  orgImgPath: string;
  cost: string;
  isFree: boolean;
  isVerified: boolean;
  link: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  icon,
  service,
  organization,
  orgImgPath,
  cost,
  isVerified,
  isFree,
  link
}) => {
  return (
    <div className="bg-white rounded-lg border border-slate-200 shadow-sm p-2.5 gap-2.5 flex flex-col items-center">
      <div className="w-full h-fit bg-slate-50 gap-2.5 px-7 py-10 flex flex-col items-center  justify-center rounded-xl">
        <div className="w-10 h-10 bg-cyan-800 text-xl text-white rounded-full flex items-center justify-center">
          <MaterialIcon label={icon} />
        </div>
        <span className="text-sm w-full font-medium truncate text-slate-950 text-center">
          {service}
        </span>
      </div>
      <div className="w-full flex gap-2 justify-start items-center px-3.5">
        <img src={orgImgPath} className="w-6 h-6 rounded-full"/>
        <p className="text-xs text-slate-950 font-medium text-center">
          {organization}
        </p>
        {isVerified && (
          <MaterialIcon label="verified" className="text-emerald-500" />
        )}
      </div>
      <div className="w-full border-b border-slate-200" />
      <div className="w-full flex justify-between items-center p-2">
        <p className="text-xs font-semibold text-slate-950">
          {isFree ? 'Gratuit' : cost}
        </p>
        <Link to={link} className="text-blue-600 text-xs flex gap-1 items-center">
          Voir plus <MaterialIcon className="text-base" label="chevron_right" />
        </Link>
      </div>
    </div>
  );
};

export default ServiceCard;
