import styles from './catalogue.module.css';

export const  DomaineComponent = ()  =>{

    return (<><div className={styles.domaineContainer}>
            <span className={styles.textDomaine}>
            Explorer les domaines les plus populaires 
            </span>
            <div className='flex gap-14'>
            <img src='/pictures/domaine-1.svg'></img>
            <img src='/pictures/domaine-2.svg'></img>
            <img src='/pictures/domaine-3.svg'></img>
            </div>
        </div>
        <div className='flex flex-col gap-3 p-7 dark:bg-slate-900'>
            <div className='flex flex-col items-center self-stretch'>
            <span className={styles.rscTitle}>Ressources et support</span>
            <span  className={styles.rscText}>Explorez notre documentation complète et accédez à des ressources utiles</span>
            </div>
            <div className='flex p-5 items-center justify-center gap-5'>
                <div className={styles.iconRscContainer}><img src='/pictures/library_books.svg'></img><span>Guides et documentation </span> </div>
                <div className={styles.iconRscContainer}><img src='/pictures/video_library.svg'></img><span>Tutoriels et vidéos</span></div>
                <div className={styles.iconRscContainer}><img src='/pictures/groups.svg'></img><span>Communauté</span></div>
                <div className={styles.iconRscContainer}><img src='/pictures/contact_support.svg'></img><span>Questions fréquentes</span></div>
            </div>
        </div>
        </>);
}