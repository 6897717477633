import styles from './advanced-upload.module.css'
import classNames from "classnames";
import {DragEvent} from 'React'
import {ChangeEvent, FC, useRef} from "react";


interface AdvancedUploaderProps{
    title?:string;
    description?:string;
    uploaderClassName?:string;
    onUploadFiles:(files:File[])=>void;
}
export const AdvancedUploader:FC<AdvancedUploaderProps> =(props)=>{
    const {title="",description="",uploaderClassName=""}=props
    const uploaderSectionStyles=classNames({
        [styles.uploaderContainer]:true,
        [uploaderClassName]:true,
        [styles["portal-advanced_uploader-css-var"]]:true
    })
    const ALLOWED_FORMATS = ['application/pdf', 'image/png', 'image/jpeg'];
    const inputRef=useRef<HTMLInputElement>(null);
    const handleDragOver=(e:DragEvent<HTMLDivElement>)=>e.preventDefault();
    const handleUploaderChange=(e:ChangeEvent<HTMLInputElement>)=>{
        const uploadedFiles = e.target.files;
        if (uploadedFiles)  handleUploadedFiles(e.target.files)
        if(inputRef.current) inputRef.current.value="";
    }
    const handleUploadedFiles=(files: FileList|null)=>{
            if(!files) return;
           const validFiles = Array.from(files).filter(file => ALLOWED_FORMATS.includes(file.type));
            if (validFiles.length > 0) props.onUploadFiles(validFiles);
    }
    const handleDrop=(e:DragEvent<HTMLDivElement>)=>{
        e.preventDefault();
        const droppedFiles = e.dataTransfer.files;
        handleUploadedFiles(droppedFiles);
    }
    const handleClick=()=>{
         if(inputRef.current) inputRef.current.click()
    }

    return (
        <div >
            <div className={uploaderSectionStyles} onDragOver={handleDragOver} onDrop={handleDrop} onClick={handleClick}>
            <input ref={inputRef} className="hidden" accept=".jpg, .jpeg, .png, .pdf" type='file' onChange={handleUploaderChange}/>
            <div className="flex flex-col items-center gap-3">
                <i className="material-icon text-blue-600 !text-3xl">upload</i>
                <span className={styles.uploaderTitle}>{title}</span>
                <span className={styles.uploaderDescription}>{description}</span>
            </div>
            </div>

        </div>
    )
}

