import {ImageUploader, WidowElementCard} from "../../../../../components/ui";
import classNames from "classnames";
import {InputText, Select,InputPhone ,TextArea} from "../../../../../../../shared/components/controlled-ui";
import {
    InputText as NativeInputText,
    TextArea as NativeTextArea,
    InputPhone as NativeInputPhone,
    Select as NativeSelect,
    NotificationCard,
    GenericTable
} from "../../../../../../../shared/components"
import {useForm} from "react-hook-form";
import {ChangeEvent, FC, useEffect, useMemo, useState} from "react";
import {Map,MapModal,InputSearch} from "../../../../../../../shared/components";
import {GuichetStatus, NotificationType} from "../../../../../../../shared/utils/enums";
import {yupResolver} from "@hookform/resolvers/yup";
import {IdentityOrganizationSchema} from "../../../../../holders/schemas";
import {
    AllGuichetsRequest,
    AllGuichetsResponse,
    IdentityCreateOrganizationForm,
    WindowOrganizationRequestsRequest
} from "../../../../../types";
import {
    useCancelJoiningOrganization,
    useGetAllOrganizations, useGetCheckWindowOrganizationStatus,
    useGetOrganizationByWindow,
    usePostCreateOrganization,
    useRequestAssociateOrganization
} from "../../../../../api";
import {removePrefixFromBase64, transformExtension} from "../../../../../../../shared/utils/mapper";
import {useParams} from "react-router-dom";
import {message, Spin} from 'antd'
import {getWindowRequestsColumns} from "../../../../../holders/table-columns";
import {CheckBadgeIcon, ClockIcon} from "@heroicons/react/16/solid";
import {TagElement,Button} from "../../../../../../../shared/components";
import {useGetWindowOrganizationAssociationRequests} from "../../../../../api/";
import {useQueryClient} from "@tanstack/react-query";
import {LogoUploadRequest} from "../../../../../../../shared/types";
import {MessageInstance} from "antd/es/message/interface";

enum OrganizationInterfaces{
    CREATION="NOT_ASSOCIATED",
    JOINING="JOINING",
    ASSOCIATE_IN_PROGRESS="ASSOCIATE_IN_PROGRESS",
    ASSOCIATED="ASSOCIATED",
}
const ALL_ORGANZIATIONS_INITIAL_STATE =   {
        numPage: 1,
        pageSize: 6,
        contributeur: false,
        key: '',
        name: '',
        status: '',
        typeGuichet: '',
        reference: '',
        domainName: '',
        organisme: '',
        email: '',
        activation: '',
        verification: '',
        tags: '',
        type: '',
        role: '',
        favorite: false,
        industry: '',
        entity: '',
        recent: '',
        Fields: 'ALL',
        objectType: 'ORGANISM'
}

export const OrganizationIdentityTab=()=>{
   const [currentInterface,setCurrentInterface]=useState<OrganizationInterfaces>();
    const [messageApi, contextHolder] = message.useMessage();
    const {guichetId}=useParams();
      const interfacesStyles = (_interface: OrganizationInterfaces) =>
        classNames({
            "transition-opacity duration-400": true,
            ['show']: currentInterface === _interface,
            ['notDisplayV2']: currentInterface !== _interface
        });
    const getCheckWindowOrganizationStatus=useGetCheckWindowOrganizationStatus({guichetId});
    const handleInterfaceSwitch=(_interface:OrganizationInterfaces)=>setCurrentInterface(_interface)
    useEffect(() => {
        console.log("dddci",getCheckWindowOrganizationStatus.isSuccess);
    if (getCheckWindowOrganizationStatus.customHandler.isSuccess && !getCheckWindowOrganizationStatus.isFetching)  {
            switch(getCheckWindowOrganizationStatus.customHandler.state){
            case"NOT_ASSOCIATED":setCurrentInterface(OrganizationInterfaces.CREATION);break;
            case"ASSOCIATE_IN_PROGRESS":setCurrentInterface(OrganizationInterfaces.ASSOCIATE_IN_PROGRESS);break;
            case"ASSOCIATED":setCurrentInterface(OrganizationInterfaces.ASSOCIATED);break;
            }
    }
    }, [getCheckWindowOrganizationStatus.customHandler.isSuccess,getCheckWindowOrganizationStatus.isFetching]);
    console.log("dddc",getCheckWindowOrganizationStatus,currentInterface);
      return (
        <div>
            {contextHolder}
            {
                getCheckWindowOrganizationStatus.isFetching &&
                <div className="w-full flex justify-center min-h-[30vh] items-center">
                <Spin size="large"/>
                </div>
            }
            {
               getCheckWindowOrganizationStatus.customHandler.isSuccess &&  !getCheckWindowOrganizationStatus.isFetching &&<>
                    <OrganizationJoiningInterface onSwitch={handleInterfaceSwitch} messageApi={messageApi}
                                                  className={interfacesStyles(OrganizationInterfaces.JOINING)}/>
                    <OrganizationCreationInterface onSwitch={handleInterfaceSwitch} messageApi={messageApi}
                                                   className={interfacesStyles(OrganizationInterfaces.CREATION)}/>
                    <OrganizationInProgressInterface className={interfacesStyles(OrganizationInterfaces.ASSOCIATE_IN_PROGRESS)}
                                                     messageApi={messageApi}
                    />
                    <OrganizationParamsInterface className={interfacesStyles(OrganizationInterfaces.ASSOCIATED)}
                                                    messageApi={messageApi}
                    />
                </>
            }


        </div>
      )


}


interface OrganizationIdentityTabsProps {
    onSwitch?: (to: OrganizationInterfaces) => void;
    className?: string;
    messageApi: MessageInstance
}

const OrganizationCreationInterface: FC<OrganizationIdentityTabsProps> = (props) => {
    const queryClient = useQueryClient()
    const {className = ""} = props;
    const {guichetId} = useParams();
    const postCreateOrganization = usePostCreateOrganization();
    const sectionTitleStyle = classNames("text-blue-700 text-sm font-semibold")
    const {
        control,
        watch,
        handleSubmit,
        formState: {errors}
    } = useForm<IdentityCreateOrganizationForm>({resolver: yupResolver(IdentityOrganizationSchema)});
    const selectedType = watch('type');
    const [position, setPosition] = useState<L.LatLngLiteral | undefined>(
        undefined
    );
   const{messageApi}=props;
    const [errorMessage, setErrorMessage] = useState<string | undefined>();
    const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
    const [imageData,setImageData]=useState<LogoUploadRequest>({format:"",base64:""})
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const rootStyles = classNames({
        [className]: true
    })
    const errorMessageStyles = classNames({
        ['!my-4']: true,
        ['notShow']: !isErrorDisplayed,
        ['show transition-all duration-500']: isErrorDisplayed
    });
    const handleImageUploaderChange = (
            image: string | undefined,
            error: string | undefined,
            format: string | undefined
        ) => {
            setIsErrorDisplayed(true);
            setErrorMessage(error);
            if (image && format) {
                setImageData({
                    format: `.${transformExtension(format)}`,
                    base64: removePrefixFromBase64(image)
                });
                setIsErrorDisplayed(false);
            } else setImageData({format:null,base64:null});
        }

    const onSubmit=(data:IdentityCreateOrganizationForm)=>{
        const coordinates={latitude:position?.lat?.toString()?? null,longitude:position?.lng?.toString() ?? null};
        const result={
            ...data,
            coordinates,
            logo:imageData,
            windowId:guichetId
        };
        postCreateOrganization.mutate(result);
    }
    const alertKey = 'create-organization-tab';

    useEffect(() => {
        if (postCreateOrganization.isPending) {
            messageApi.loading({
                key: alertKey,
                content: "loading",
                duration: 0
            });
        }
    }, [postCreateOrganization.isPending]);

    useEffect(() => {
        if (postCreateOrganization.customHandler.isError) {
            messageApi.error({
                key: alertKey,
                content:postCreateOrganization.customHandler.errorMessage ,
                duration: 2.5
            });
        }
    }, [postCreateOrganization.customHandler.isError]);


    useEffect(() => {
        if (postCreateOrganization.customHandler.isSuccess) {
            messageApi.success({
                key: alertKey,
                content: postCreateOrganization.customHandler.successMessage,
                duration: 2.5
            });
            if (postCreateOrganization.customHandler.isSuccess) queryClient.invalidateQueries({ queryKey: ['organization-window-status'] })
        }
    }, [postCreateOrganization.customHandler.isSuccess]);
    const handleSwitch=()=>{
        if (props.onSwitch) props.onSwitch(OrganizationInterfaces.JOINING);
    }

    return (<div className={rootStyles}>
        <NotificationCard
            type={NotificationType.ERROR}
            className={errorMessageStyles}>
            {errorMessage}
        </NotificationCard>
        <div className="flex flex-col gap-7">
            <ImageUploader onChange={handleImageUploaderChange}/>
            <div className="flex flex-col gap-5 justify-center">
                <span className={sectionTitleStyle}>Informations générales</span>
                <div className="grid grid-cols-3 gap-4">
                    <InputText control={control} size="md" label="Nom" placeholder="Entrez le nom de votre organisation"
                               name="name" error={errors.name?.message} withError/>
                    <InputText control={control} size="md" label="Acronyme" placeholder="Entrez l’acronyme de votre organisation" name="acronym" error={errors.acronym?.message} withError/>
                    <NativeInputText  size="md" label="Propriétaire" disabled />
                </div>

                <TextArea label="Description" control={control} name="description" size="md" placeholder="Décrivez brièvement votre organisation" error={errors.description?.message} withError/>
            </div>

            <div className="flex flex-col gap-4 justify-center">
                <span className={sectionTitleStyle}>Coordonnées</span>
                <div className="grid grid-cols-2 grid-rows-2 items-center justify-center gap-4">
                    <InputText control={control} size="md" label="Email" placeholder="Entrez l’email de votre organisation" name="email" error={errors.email?.message} withError/>
                    <InputPhone control={control} label="Téléphone" prefixPlaceholder="indicatif" placeholder="Tappez le numéro de téléphone" size="md" error={errors.phoneNumber?.message} withError  name="phoneNumber"/>
                    <InputText control={control} size="md" label="N° identifiant" placeholder="Entrez l’identifiant de votre organisation" name="identifier" error={errors.identifier?.message} withError/>
                    <InputText control={control} size="md" label="Site web" placeholder="Entrez le site web de votre organisation" name="webSite" error={errors.webSite?.message} withError/>
                </div>

            </div>


            <span className={sectionTitleStyle}>Adresse</span>
            <div
                style={{
                    gridTemplateColumns:
                        selectedType === 'Physique' ? '90% 10%' : '100%'
                }}
                className="grid">
                <Select
                    control={control}
                    error={""}
                    placeholder="Chosissez un élément"
                    size="lg"
                    label={"Type"}
                    name="type"
                    options={[{label: 'Physique', value: 'Physique'}, {label: 'Virtuel', value: 'Virtuel'}]}
                />
                {selectedType === 'Physique' && (
                    <div className="relative flex justify-center items-center">
                        {!position && (
                            <span
                                className="absolute text-red-600 font-bold left-[22%] top-[22%] text-lg material-flex-icon">
                          error
                        </span>
                        )}
                        <span
                            onClick={() => setIsOpen(!isOpen)}
                            className="material-flex-icon text-4xl text-blue-600 cursor-pointer">
                        location_on
                      </span>
                    </div>
                )}
            </div>
            <MapModal isOpen={isOpen} onClose={()=>{}}>
                <Map position={position} onChoosePosition={setPosition}/>
            </MapModal>
            <div className="flex gap-3 justify-end w-full">
                <Button size="sm" label="Associer organisation" color="primary" styling="outline" onClick={handleSwitch}/>
                <Button size="sm" label="Créer organisation" color="primary" styling="solid" onClick={handleSubmit(onSubmit)}/>
            </div>
        </div>
    </div>)
}

const OrganizationJoiningInterface:FC<OrganizationIdentityTabsProps> = (props) => {
    const queryClient = useQueryClient()
    const {className=""}=props;
    const [allOrganizationsRequest,setAllOrganizationsRequest]=useState<AllGuichetsRequest>(ALL_ORGANZIATIONS_INITIAL_STATE)
    const [selectedOrganization,setSelectedOrganization]=useState<AllGuichetsResponse>()
    const getAllOgranizations=useGetAllOrganizations(allOrganizationsRequest);
    const requestAssociateOrganization=useRequestAssociateOrganization();
    const {guichetId}=useParams();
    const{messageApi}=props;


    const alertKey = 'join-organization-tab';

    useEffect(() => {
        if (requestAssociateOrganization.isPending) {
            messageApi.loading({
                key: alertKey,
                content: "loading",
                duration: 0
            });
        }
    }, [requestAssociateOrganization.isPending]);

    useEffect(() => {
        if (requestAssociateOrganization.customHandler.isError) {
            messageApi.error({
                key: alertKey,
                content:requestAssociateOrganization.customHandler.errorMessage ,
                duration: 2.5
            });
        }
    }, [requestAssociateOrganization.customHandler.isError]);


    useEffect(() => {
        if (requestAssociateOrganization.customHandler.isSuccess) {
            messageApi.success({
                key: alertKey,
                content: requestAssociateOrganization.customHandler.successMessage,
                duration: 2.5
            });
            if (requestAssociateOrganization.customHandler.isSuccess) queryClient.invalidateQueries({ queryKey: ['organization-window-status'] })
        }
    }, [requestAssociateOrganization.customHandler.isSuccess]);

    const rootStyles=classNames({
        "flex flex-col justify-center gap-4":true,
        [className]:true
    })
    const cardStyles=(checked:boolean)=>(classNames({
        "notDisplay":checked,
        "transition-colors transition-visibility duration-700":true
    }))
    const selectedCardContainerStyles=(enabled:boolean)=>(classNames({
        "show":enabled,
        "notDisplayV2":!enabled,
        "transition-all duration-700":true
    }))
    const renderOrganizationImage=(gedId:string|undefined)=>{
      if (gedId)  return <img className="rounded-full" src={`/karazal/DownloadFile?gedId=${gedId}`} alt=""/>
      return <></>;
    }
    const handleSearchChange=(e:ChangeEvent<HTMLInputElement>)=>{
        setAllOrganizationsRequest({...allOrganizationsRequest,name:e.target.value})
    }
    const handleCheckOrganization=(id:string)=>{
        setSelectedOrganization(getAllOgranizations.customHandler.data?.data.find((item)=>item.guichetId===id))
    }
   const renderLoader=()=>{
       if (getAllOgranizations.isFetching || getAllOgranizations.isLoading) return  <div className="flex min-h-24 justify-center items-center"><Spin size="large"/></div>
       return <></>
    }
   const handleAssociateOrganization=()=>{
        if (!(guichetId && selectedOrganization?.guichetId)) return ;
        const request ={guichetId,organisationId:selectedOrganization?.guichetId};
        requestAssociateOrganization.mutate(request);
    }
   const handleSwitch=()=>{
    if (props.onSwitch) props.onSwitch(OrganizationInterfaces.CREATION);
   }
    return (
        <div className={rootStyles}>
            <span className="font-semibold text-sm text-blue-700">Associer votre guichet à une organisation</span>
            <InputSearch size="lg"  onChange={handleSearchChange} placeholder="Rechercher"/>
            <div className="flex flex-col gap-6 w-full min-h-44 ">
                {
                    <div className={selectedCardContainerStyles(selectedOrganization!==undefined)}>
                        {selectedOrganization &&   <WidowElementCard  title={selectedOrganization.name} checked={true} state={selectedOrganization.status || ""} onCheck={handleCheckOrganization} icon={renderOrganizationImage(selectedOrganization.logoGedId)} cardKey={selectedOrganization.guichetId}/>}
                   </div>
                }
                <div className="flex flex-col gap-2 w-full">
                    {(getAllOgranizations.customHandler.isSuccess) && !getAllOgranizations.isFetching && getAllOgranizations.customHandler.data?.data.map((data)=>(
                        <div key={data.guichetId} className={cardStyles(data.guichetId===selectedOrganization?.guichetId)}>
                            <WidowElementCard  title={data.name} checked={data.guichetId===selectedOrganization?.guichetId} state={data.status || ""} onCheck={handleCheckOrganization} icon={renderOrganizationImage(data.logoGedId)} cardKey={data.guichetId}/>
                        </div>
                    ))}
                </div>
                {renderLoader()}
            </div>
            <div className="flex gap-3 justify-end w-full">
                <Button size="sm" label="Créer organisation"   color="primary" styling="outline" onClick={handleSwitch}/>
                <Button size="sm" label="Associer organisation"   color="primary" styling="solid" onClick={handleAssociateOrganization}/>
            </div>
        </div>)
}

  const OrganizationInProgressInterface:FC<OrganizationIdentityTabsProps>=(props)=>{
    const {className=""}=props
    const queryClient = useQueryClient()
    const {guichetId}=useParams();
    const getCheckWindowOrganizationStatus=useGetCheckWindowOrganizationStatus({guichetId});
      const{messageApi}=props;
    const alertKey ="key-add"
    const cancelJoiningRequest=useCancelJoiningOrganization();
      const renderStateIcon=(status:string)=>{
          switch (status){
              case GuichetStatus.IN_PROGRESS: return <ClockIcon className="size-3 text-orange-600 "/>;
              case GuichetStatus.VERIFIED:  return <CheckBadgeIcon className="size-3 text-emerald-500"/>
          }
      }

      const renderOrganizationImage=(gedId:string|undefined)=> {
          if (gedId) return <img className="rounded-full" src={`/karazal/DownloadFile?gedId=${gedId}`} alt=""/>
          return <></>;
      }
      const rootSytles=classNames({
          [className]:true,
          ["flex flex-col gap-7"]:true
      })

      const handleCancelRequestClick=()=>{
          const organisationId=getCheckWindowOrganizationStatus.customHandler.data?.organisationId;
          const joindemandeId=getCheckWindowOrganizationStatus.customHandler.data?.demandeJoindreId;
          if (organisationId && guichetId && joindemandeId)  cancelJoiningRequest.mutate({guichetId,organisationId,joindemandeId})
      }
      useEffect(() => {
          if (cancelJoiningRequest.isPending) {
              messageApi.loading({
                  key: alertKey,
                  content: "loading",
                  duration: 0
              });
          }
      }, [cancelJoiningRequest.isPending]);

      useEffect(() => {
          if (cancelJoiningRequest.customHandler.isError) {
              messageApi.error({
                  key: alertKey,
                  content:cancelJoiningRequest.customHandler.errorMessage ,
                  duration: 2.5
              });
          }
      }, [cancelJoiningRequest.customHandler.isError]);


      useEffect(() => {
          if (cancelJoiningRequest.customHandler.isSuccess) {
              messageApi.success({
                  key: alertKey,
                  content: cancelJoiningRequest.customHandler.successMessage,
                  duration: 2.5
              });
              if (cancelJoiningRequest.customHandler.isSuccess) queryClient.invalidateQueries({ queryKey: ['organization-window-status'] })
          }
      }, [cancelJoiningRequest.customHandler.isSuccess]);
    return (
        <div className={rootSytles}>
            <span className="font-semibold text-sm text-blue-700">Demande d’association guichet à une organisation</span>
            <div className="flex gap-2">
                <div className="flex justify-center  h-10 aspect-square rounded-full">{renderOrganizationImage(getCheckWindowOrganizationStatus.data?.body?.gedId)}</div>
                <div className="flex w-full justify-between items-center">
                    <div className="flex items-center gap-1">
                        <span
                            className="font-medium text-sm">{getCheckWindowOrganizationStatus.data?.body?.organisationName}</span>
                        {renderStateIcon("")}
                    </div>
                    <TagElement color="#C2410C" borderColor="#FB923C" label="En attente approbation" className="!bg-orange-50"/>
                </div>
            </div>
            <div className="flex justify-end w-full">
                <Button size="md" color="error" styling={"solid"} label="Annuler demande" onClick={handleCancelRequestClick}/>
            </div>
        </div>
    )
  }

export const OrganizationParamsInterface: FC<OrganizationIdentityTabsProps> = (props) => {
    const {className = ""} = props;
    const {guichetId} = useParams();
    const getOrganizationByWindow=useGetOrganizationByWindow({guichetId});


    const [filterRequest,setFilterRequest]=useState<WindowOrganizationRequestsRequest>({organismeId:guichetId ?? "",organismName:"",numPage:"1",pageSize:"3",statusRequest:""});
    const getWindowOrganizationRequests=useGetWindowOrganizationAssociationRequests(filterRequest);
 const rootStyles=classNames({
     [className]:true,
     "flex flex-col gap-7":true
 })
    const handleSearchChange=(e:ChangeEvent<HTMLInputElement>)=>{
     setFilterRequest({...filterRequest,organismName:e.target.value});
    }
    const handleSelectStatusChange=(e:Event)=>{
     const inputElement=e.target as HTMLInputElement
    setFilterRequest({...filterRequest,statusRequest:inputElement.value});
    }
    const sectionTitleStyle = classNames("text-blue-700 text-sm font-semibold")
    const COLUMNS=useMemo(getWindowRequestsColumns,[])
    const STATUS_REQUEST_OPTIONS=[{label:"En attente approbation",value:"Brouillon"},{label:"Supprimée",value:"Supprimée"},{label:"Approuvée",value:"Approuvée"},{label:"Refusée",value:"Refusée"}];
    return (
     <div className={rootStyles}>
         <ImageUploader readOnly defaultImageUrl={"https://picsum.photos/200/300"}/>
         <div className="flex flex-col gap-5 justify-center">
             <span className={sectionTitleStyle}>Informations générales</span>
             <div className="grid grid-cols-3 gap-4">
                 <NativeInputText size="lg" label="Nom" value={getOrganizationByWindow.customHandler.data?.name} readOnly/>
                 <NativeInputText  size="lg" label="Acronyme"  value={getOrganizationByWindow.customHandler.data?.acronyme}  readOnly />
                 <NativeInputText  size="lg" label="Propriétaire"  value={getOrganizationByWindow.customHandler.data?.Proprietaire}  readOnly />
             </div>

             <NativeTextArea label="Description"  size="lg" value={getOrganizationByWindow.customHandler.data?.description} readOnly />
         </div>

         <div className="flex flex-col gap-4 justify-center">
             <span className={sectionTitleStyle}>Coordonnées</span>
             <div className="grid grid-cols-2 grid-rows-2 items-center justify-center gap-4">
                 <NativeInputText size="lg" label="Email" value={getOrganizationByWindow.customHandler.data?.email} readOnly/>
                 <NativeInputPhone label="Téléphone" prefixPlaceholder="indicatif" size="lg" value={{prefix:undefined,suffix:getOrganizationByWindow.customHandler.data?.phoneNumber}} disabled  />
                 <NativeInputText size="lg" label="N° identifiant" value={getOrganizationByWindow.customHandler.data?.identifiant} readOnly/>
                 <NativeInputText size="lg" label="Site web" value={getOrganizationByWindow.customHandler.data?.site} readOnly/>
             </div>
         </div>
         <div className="flex flex-col gap-4">
             <span className={sectionTitleStyle}>Guichets associés</span>
             <div className="grid grid-cols-[70%_25%] gap-3 ">
                 <InputSearch placeholder="Rechercher" size="lg"  onChange={handleSearchChange} label="Rechercher" hideLabel />
                 <NativeSelect options={STATUS_REQUEST_OPTIONS} label="Statut" placeholder="Statut" size="lg" onChange={handleSelectStatusChange}/>
             </div>


         </div>
         <GenericTable data={getWindowOrganizationRequests.data?.body?.data || []} columns={COLUMNS} isLoading={getWindowOrganizationRequests.isFetching}/>
     </div>
 )
}


